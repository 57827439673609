/* This file should contain any global styles which are required after the migration to Tailwind CSS, such as third party modules which cannot be styled directly with Tailwind CSS classes. */

/* Once the migration to Next is complete, we should replace all colors in this file to use theme We should change this to use theme functions, e.g. theme('colors.gray.DEFAULT'), instead of hardcoded values. */

@layer globals {
  /* === GENERAL GLOBAL STYLES === */

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body:before {
    content: 'mobile';
    display: none;
  }

  @media (min-width: 768px) {
    body:before {
      content: 'tablet';
    }
  }

  @media (min-width: 1440px) {
    body:before {
      content: 'desktop';
    }
  }

  html,
  body {
    height: 100%;
  }

  body {
    color: #424242;
    font-family: 'interstate', 'sans-serif';
    font-weight: 400;
    overflow-anchor: none;
    text-rendering: optimizeLegibility;
    width: 100%;
  }

  body > #root {
    height: 100%;
  }

  input,
  select,
  textarea,
  button {
    font-family: 'interstate', 'sans-serif';
  }

  @media (hover: hover) {
    a,
    button {
      cursor: pointer;
    }
  }

  *:focus {
    outline: none;
  }

  a:focus-visible,
  button:focus-visible {
    outline-color: #107c8c;
    outline-style: auto;
    outline-width: 2px;
    outline-offset: 2px;
  }

  img {
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }

  /* === LINK COLOR AND HOVER STYLES === */

  /* This provides global styling for all links across the app that do not:
    - have custom border, background, color or underline styles
    - have display set to flex
    - contain a child element with a border
    - contain an img or svg element
    - have styles applied from an ancestor element using the [&_a] selector */
  a:not([class*='border']):not([class*='bg']):not([class*='color']):not([class*='underline']):not([class*='flex']):not(:has([class*='border'])):not(:has(img)):not(:has(svg)):not([class*='_a]']
      a) {
    color: #107c8c;
  }

  a:not([class*='border']):not([class*='bg']):not([class*='color']):not([class*='underline']):not([class*='flex']):not(:has([class*='border'])):not(:has(img)):not(:has(svg)):not([class*='_a]']
      a):hover {
    text-decoration: underline;
  }

  /* === TEXT STYLES USED BY CONTENT FROM ADMIN === */

  .text-highlight,
  .text-default,
  .text-light {
    color: #424242;
  }

  /* === THIRD PARTY STYLES: UJET */

  #ujet-launcher {
    display: none;
  }

  #ujet-launcher iframe {
    width: 62px !important;
    height: 62px !important;
  }

  /* PhotoSwipe plugin, used in PDPPinchToZoomGallery component */

  @keyframes pinchToZoomFadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  .PDPPinchToZoomGallery__PhotoSwipe .pswp__bg {
    background-color: white;
  }

  .PDPPinchToZoomGallery__PhotoSwipe .pswp__img {
    background-color: white;
  }

  .PDPPinchToZoomGallery__PhotoSwipe .pswp__top-bar {
    background-color: rgba(255, 255, 255, 0.75) !important;
  }

  .PDPPinchToZoomGallery__PhotoSwipe .pswp__counter {
    align-items: center;
    color: #424242;
    font-size: 0.75rem;
    left: auto !important;
    letter-spacing: 0.025rem;
    line-height: 1.1rem;
    margin-right: 4px;
    margin-top: 14px;
    opacity: 1;
    right: 0;
  }

  .PDPPinchToZoomGallery__PhotoSwipe .pswp__button--zoom,
  .PDPPinchToZoomGallery__PhotoSwipe .pswp__button--share,
  .PDPPinchToZoomGallery__PhotoSwipe .pswp__button--fs,
  .PDPPinchToZoomGallery__PhotoSwipe .pswp__button--arrow--left,
  .PDPPinchToZoomGallery__PhotoSwipe .pswp__button--arrow--right {
    display: none !important;
  }

  .PDPPinchToZoomGallery__PhotoSwipe .pswp__container:after {
    animation: pinchToZoomFadeOut 1s ease-in 2s forwards;
    background-color: rgba(38, 38, 38, 0.4);
    border-radius: 2px;
    color: white;
    content: 'Pinch to zoom';
    font-size: 0.75rem;
    font-weight: 500;
    height: 14px;
    left: 50%;
    letter-spacing: 0.025rem;
    line-height: 1.1rem;
    padding: 4px 8px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: calc(50% - 11px);
    transform: translateX(-50%);
    width: auto;
    z-index: 1000;
  }

  @media (min-width: 1200px) {
    .PDPPinchToZoomGallery__PhotoSwipe .pswp__container:after {
      display: none;
    }
  }

  .PDPPinchToZoomGallery__PhotoSwipe .pswp__button--close {
    background-color: white !important;
    background: url("data:image/svg+xml,%0A%3Csvg width='12' height='16' viewBox='0 0 15 24' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.579 23L2 11.155 13.579 1' stroke='%23262626' stroke-width='2' fill='none' fill-rule='evenodd' /%3E%3C/svg%3E")
      center center no-repeat !important;
    left: 0;
    position: absolute;
  }

  :root {
    --visible-promotion-banner-height: 0;
  }

  .react-responsive-modal-root,
  .react-responsive-modal-modal {
    z-index: 1006 !important;
  }

  .react-responsive-modal-root {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  .react-responsive-modal-modal {
    background: #fff;
    display: inline-block;
    margin: 0 !important;
    overflow-y: auto;
    position: relative;
    text-align: left;
    top: 0 !important;
    vertical-align: middle;
  }

  .react-responsive-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1 !important;
  }

  .react-responsive-modal-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
  }

  /* DayPicker base styles */

  .DayPicker {
    background-color: #fafafa;
    border: 1px solid #f1f1f1;
  }
  .DayPicker-Day {
    height: 40px;
    width: 40px;
  }
  .DayPicker-Weekday {
    color: #737373 !important;
  }
  .DayPicker-Day:not(.DayPicker-Day--disabled) {
    color: #424242;
    font-weight: bold !important;
  }
  .DayPicker-Day--disabled {
    color: #737373 !important;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
    background-color: #107c8c !important;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRange,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRangeStart,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRangeEnd,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--outside {
    background-color: #107c8c !important;
    border-color: #107c8c !important;
    color: #fff !important;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRange.DayPicker-Day--hoverRange,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRangeStart.DayPicker-Day--hoverRange,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRangeEnd.DayPicker-Day--hoverRange,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--outside.DayPicker-Day--hoverRange,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRange.DayPicker-Day--hoverRangeStart,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRangeStart.DayPicker-Day--hoverRangeStart,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRangeEnd.DayPicker-Day--hoverRangeStart,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--outside.DayPicker-Day--hoverRangeStart,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRange.DayPicker-Day--hoverRangeEnd,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRangeStart.DayPicker-Day--hoverRangeEnd,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRangeEnd.DayPicker-Day--hoverRangeEnd,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--outside.DayPicker-Day--hoverRangeEnd {
    color: #fff !important;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRange
    .SelectedWeekDatePicker,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRangeStart
    .SelectedWeekDatePicker,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--selectedRangeEnd
    .SelectedWeekDatePicker,
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled).DayPicker-Day--outside
    .SelectedWeekDatePicker {
    background-color: #107c8c !important;
    border-color: #107c8c !important;
    color: #fff !important;
  }

  /* react-instantsearch-dom styles  */

  .ais-SearchBox-submit {
    display: none;
  }

  .ais-SearchBox {
    width: inherit;
  }

  .ais-SearchBox-form {
    display: flex;
  }

  .ais-SearchBox-reset {
    align-items: center;
    background: inherit;
    border: none;
    cursor: pointer;
    display: none !important;
  }

  .ais-SearchBox-reset svg {
    fill: #424242;
    width: 12px;
    height: 12px;
  }

  .ais-SearchBox-input {
    appearance: unset;
    background: none;
    border: 1px solid transparent;
    color: #333333;
    display: block;
    font-family: 'interstate', 'sans-serif';
    font-size: 1rem;
    font-weight: 500;
    height: 40px;
    letter-spacing: -0.0125rem;
    line-height: 1.45rem;
    width: 100%;
  }

  .ais-SearchBox-input::placeholder {
    text-align: left;
  }

  .ais-SearchBox-input::-ms-clear,
  .ais-SearchBox-input::-ms-reveal {
    display: none !important;
    width: 0;
    height: 0;
  }

  .ais-SearchBox-input::-webkit-search-cancel-button,
  .ais-SearchBox-input::-webkit-search-decoration,
  .ais-SearchBox-input::-webkit-search-results-button,
  .ais-SearchBox-input::-webkit-search-results-decoration {
    display: none !important;
  }

  .ais-SearchBox-submitIcon {
    display: none;
  }

  /* Marxent configurator styles */

  .mxt-sectional-config-container
    .mxt-sectional-config-scene-container
    .config-container-bottom-right {
    display: none !important;
  }

  .mxt-sectional-config-container
    .mxt-sectional-config-scene-container
    .config-container-top-right
    .mxt-nav-control-button-group:nth-child(3) {
    display: none !important;
  }

  /* react-autosuggest styles, used by BaseField component */

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__suggestions-container {
    background-color: #fff;
    border-radius: 2px;
    border-top: 1px solid #e5e5e5;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    display: none;
    overflow: hidden;
    padding: 15px 10px;
    position: absolute;
    width: 100%;
    z-index: 1000;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 8px 16px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #f6f6f6;
  }

  /* Initial CSS variables */

  :root {
    --push-across-sidebar-open-width: 100vw;
  }

  @media (min-width: 768px) {
    :root {
      --push-across-sidebar-open-width: 500px;
    }
  }

  @media (min-width: 1280px) {
    :root {
      --push-across-sidebar-open-width: 620px;
    }
  }

  @media (min-width: 1440px) {
    :root {
      --push-across-sidebar-open-width: 650px;
    }
  }

  @media (min-width: 1800px) {
    :root {
      --push-across-sidebar-open-width: 875px;
    }
  }

  .contentful-promotion-hero .__react_component_tooltip {
    opacity: 1;
    padding: 0;
  }

  .FabricGallery-desktop-slider .iiz__img--abs {
    object-fit: cover;
    object-position: right bottom;
  }

  .inspiration-page .__react_component_tooltip.place-bottom,
  .inspiration-page .__react_component_tooltip.place-top {
    margin-top: 0;
    padding: 0;
  }

  .inspiration-page .__react_component_tooltip {
    border-radius: 0.5rem;
    opacity: 1;
    padding: 0 !important;
    z-index: 20;
  }

  .inspiration-page .__react_component_tooltip:after {
    border-top-color: inherit !important;
  }

  .inspiration-page .__react_component_tooltip:not(.show) {
    display: none;
  }

  .pdp-wistia-video .wistia_responsive_padding {
    padding-bottom: 56.25%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 56.25%;
    position: relative;
    width: 100vw;
  }

  .pdp-wistia-video .wistia_responsive_wrapper {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .pdp-wistia-video .wistia_swatch.wistia_swatch_img_loaded {
    opacity: 1;
  }

  .pdp-wistia-video .wistia_swatch {
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: opacity 0.2s;
    width: 100%;
  }

  .pdp-wistia-video .wistia_swatch img {
    filter: blur(5px);
    height: 100%;
    object-fit: contain;
    width: 100%;
  }

  .pdp-dropdown-field .react-select__menu-list {
    color: #333;
    font-size: 0.8125rem;
    font-weight: 500;
    letter-spacing: 0.0187rem;
    line-height: 1.4375rem;
    max-height: 198px;
  }

  .pdp-dropdown-field .react-select__menu {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-color: #ccc;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 0 0 #000;
    font-size: 0.8125rem;
    font-weight: 500;
    left: 0;
    letter-spacing: 0.0187rem;
    line-height: 1.4375rem;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: -1px;
    max-height: 200px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 2000;
  }

  .pdp-dropdown-field .react-select__single-value {
    align-items: center;
    display: flex;
    font-size: 0.8125rem;
    font-weight: 500;
    letter-spacing: 0.0187rem;
    line-height: 1.4375rem;
  }

  .pdp-dropdown-field .react-select .Select-value-label {
    width: 90%;
  }

  /* Search type input style sanitization*/
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}
